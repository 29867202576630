import React from 'react';

import { TAvailableTime } from '../../../../../types';
import { ReactComponent as CartIcon } from '../../../../../assets/images/cart.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/images/add.svg';
import { useAppSelector } from '../../../../../hooks/hooks';
import { formatClassDate, formatToDuration } from '../../../../../helpers';

interface IAvailableTimeItem {
  availableTime: TAvailableTime;
  onClick: () => any;
}

const AvailableTimeItem: React.FC<IAvailableTimeItem> = ({
  availableTime,
  onClick,
}) => {
  const { cart } = useAppSelector((state) => state.userReducer);

  if (availableTime.isBooked || availableTime.isFull) {
    return (
      <div className="flex items-center justify-between px-2 py-1 bg-white rounded text-grey-200">
        <div className="flex flex-col">
          <div className="font-bold">
            {formatClassDate(availableTime.startTime, availableTime.endTime)}
          </div>
          <div>
            {formatToDuration(availableTime.startTime, availableTime.endTime)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`px-2 py-1 flex justify-between items-center rounded
        cursor-pointer group/schedule outline-1 transition-all outline-blue-primary
        hover:outline hover:bg-blue-100 hover:text-blue-primary ${
          cart.public.some((item) => item.id === availableTime.id)
            ? 'bg-blue-100 outline text-blue-primary [&_svg]:fill-blue-primary'
            : 'bg-white'
        }`}
      onClick={onClick}
    >
      <div className="flex flex-col">
        <div className="font-bold">
          {formatClassDate(availableTime.startTime, availableTime.endTime)}
        </div>
        <div>
          {formatToDuration(availableTime.startTime, availableTime.endTime)}
        </div>
      </div>
      <div className="flex">
        <CartIcon
          className="group-hover/schedule:fill-blue-primary"
          fill="#BABABA"
        />
        <AddIcon
          className="group-hover/schedule:fill-blue-primary"
          fill="#BABABA"
        />
      </div>
    </div>
  );
};

export default AvailableTimeItem;
