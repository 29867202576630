import React, { ReactNode, useEffect } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Navbar from './Navbar';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { NAVIGATE_PATH as PATH } from '../../../helpers/constants';
import { fetchUser } from '../../../store/reducers/actionCreators';

interface INavbarLayout {
  children?: ReactNode;
}

export const NavbarLayout: React.FC<INavbarLayout> = () => {
  const location = useLocation();
  const { user, isLoading } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  if (isLoading) {
    return null;
  }

  const isPublic = isRoutePublic();

  function isRoutePublic() {
    if (location.pathname.startsWith('/library/public-courses')) {
      return true;
    }
    return [
      PATH.libraryCalendar,
      PATH.checkoutGuest,
      PATH.secondCheckoutStep,
      PATH.checkoutThanks,
      PATH.inviteQuery,
    ].includes(location.pathname);
  }

  if (!user && !isPublic) {
    return <Navigate to={PATH.login} replace={true} />;
  }

  if (!user) {
    return (
      <div className="w-full min-h-screen bg-white group guest-view">
        <Outlet />
      </div>
    );
  }

  return (
    <div className="flex flex-col-reverse min-h-screen lg:h-full lg:flex-row bg-gray-six">
      <Navbar />
      <div className="w-full min-h-[calc(100vh-3.5rem)] mb-14 lg:min-h-screen lg:mb-0 lg:z-10 lg:rounded-l-lg bg-white shadow-main">
        <Outlet />
      </div>
    </div>
  );
};
