import React from 'react';

import { useQuery } from '@tanstack/react-query';
import ContentLoader from 'react-content-loader';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { getCourseFilters } from '../../../../../requests';
import {
  filterCourses,
  setCourseFilters,
} from '../../../../../store/reducers/librarySlice';
import CourseFilterGroup from '../CourseFilterGroup';
import CourseFilterList from '../CourseFilterList';

const CourseFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const { secondaryFilters, activeFilters } = useAppSelector(
    (state) => state.libraryReducer,
  );

  const { isLoading } = useQuery({
    queryKey: ['courseFilters'],
    queryFn: async () => {
      const filters = await getCourseFilters();
      dispatch(setCourseFilters(filters));
      return filters;
    },
  });

  return (
    <div className="hidden xl:block w-full max-w-[12.25rem] text-regular text-core-dark">
      {isLoading ? (
        <div>
          <div className="mb-2 font-bold">Filter Categories</div>
          <ContentLoader className="max-w-[12.25rem]">
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="32" />
            <rect x="0" y="40" rx="5" ry="5" width="100%" height="32" />
          </ContentLoader>
        </div>
      ) : (
        <CourseFilterGroup title="Filter Categories">
          <CourseFilterList
            filters={secondaryFilters}
            active={activeFilters.secondaryFilter}
            setActive={(secondaryFilter) =>
              dispatch(filterCourses({ secondaryFilter }))
            }
          />
        </CourseFilterGroup>
      )}
    </div>
  );
};

export default CourseFilters;
