import React, { useEffect, useMemo } from 'react';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import BackButton from '../../../components/common/buttons/BackButton';
import BookingSummary from '../../../components/common/checkout/BookingSummary';
import Delimiter from '../../../components/common/Delimiter';
import NavHeader from '../../../components/common/NavHeader';
import OrderSummaryCard from '../../../components/common/checkout/OrderSummaryCard';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { ButtonRegular } from '../../../components/common/buttons/ButtonRegular';
import { clearCart } from '../../../store/reducers/userSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';

const CheckoutThanks: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectStatus = searchParams.get('redirect_status');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cart, cartTotalToPay } = useAppSelector((state) => state.userReducer);

  const totalPayed = useMemo(() => cartTotalToPay, []);
  const appliedCoupon = useMemo(() => cart.coupon, []);
  const checkoutItems = useMemo(() => {
    return redirectStatus === 'succeeded'
      ? [...cart.processed, ...cart.public]
      : cart.processed;
  }, []);

  useEffect(() => {
    if (
      checkoutItems.length &&
      (redirectStatus === 'succeeded' || cart.status === 'completed')
    ) {
      dispatch(clearCart());
      setSearchParams(undefined);
    } else {
      navigate(NAVIGATE_PATH.checkoutSummary, { replace: true });
    }
  }, []);

  return (
    <>
      <NavHeader
        label={<BackButton defaultRoute={NAVIGATE_PATH.publicCourses} />}
      />
      <div className="w-full gap-4 pt-4 bg-white main-container guest:items-center">
        <div className="flex flex-col justify-between gap-6 guest:guest-wrapper guest:w-full xl:flex-row">
          <div className="flex flex-col flex-grow gap-4">
            <h1 className="text-4xl calistoga text-core-dark">Thank you</h1>
            <div className="flex flex-col gap-6">
              <p>
                You have successfully booked {checkoutItems.length}{' '}
                {checkoutItems.length > 1 ? 'classes' : 'class'}
              </p>
              <Link to={NAVIGATE_PATH.upcomingClasses}>
                <ButtonRegular
                  className="button-primary"
                  text="View Your upcoming classes"
                />
              </Link>
            </div>
          </div>
          <div className="w-full xl:max-w-[22rem] 3xl:min-w-[35%]">
            <OrderSummaryCard
              buttonHidden
              checkoutItems={checkoutItems}
              finalized={{ totalPayed, appliedCoupon }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-4 guest:guest-wrapper">
          <Delimiter className="bg-grey-200" />
          <h1 className="text-4xl calistoga text-core-dark">Booking summary</h1>
          <BookingSummary items={checkoutItems} />
        </div>
      </div>
    </>
  );
};

export default CheckoutThanks;
