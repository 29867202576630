import React, { Fragment, ReactNode, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import Delimiter from '../Delimiter';
import { ButtonRegular } from '../buttons/ButtonRegular';

interface IConfirmationPopup {
  title: string;
  titleClassName?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelText: string;
  confirmText: string;
  onConfirm: () => Promise<unknown>;
  children: ReactNode;
}

const ConfirmationPopup: React.FC<IConfirmationPopup> = ({
  title,
  titleClassName = 'checkout-header text-left leading-normal',
  open,
  setOpen,
  cancelText,
  confirmText,
  onConfirm,
  children,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-[9999]"
        onClose={() => setOpen(false)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[28rem] shadow-main rounded-lg transform overflow-hidden transition-all">
                  <div className="flex flex-col px-6 py-4 bg-white rounded-lg shadow-xl">
                    <Dialog.Title className={titleClassName}>
                      {title}
                    </Dialog.Title>
                    <Delimiter className="my-2" />
                    <div className="relative flex-1">{children}</div>
                    <Delimiter className="my-2" />
                    <div className="flex flex-wrap justify-between gap-2">
                      <ButtonRegular
                        className="button-outline max-md:text-xs"
                        text={cancelText}
                        onClick={() => setOpen(false)}
                      />
                      <ButtonRegular
                        className="button-primary max-md:text-xs"
                        text={confirmText}
                        disabled={loading}
                        onClick={() => {
                          setLoading(true);
                          onConfirm().finally(() => setLoading(false));
                        }}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmationPopup;
