import { AxiosResponse } from 'axios';
import { isBefore, isSameDay } from 'date-fns';
import { toast, ToastOptions } from 'react-hot-toast';
import { convertToPixelCrop, Crop } from 'react-image-crop';

import {
  ErrorCode,
  ProgramType,
  TColor,
  TResolvedInvite,
  TProgram,
  TCoupon,
  CourseType,
} from '../types';
import { icons } from './icons';
import { IUser } from '../models/IUser';
import {
  DEFAULT_ERROR_MESSAGE,
  NAVIGATE_PATH as PATH,
  NAVIGATION_INFO,
} from './constants';
import { ICompany } from '../models/ICompany';
import { cloneDeep } from 'lodash';

export const axiosResponseToPromise = <T>(
  response: AxiosResponse<T>,
): Promise<T> => {
  return new Promise((resolve) => {
    resolve(response.data);
  });
};

// Wed, 26 Mar 2025
export const formatToDate = (
  dateTime?: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!dateTime) {
    return '—';
  }
  return toLocaleDate(new Date(dateTime), options);
};

// 23:00 or 23:00 EEST
export const formatToTime = (
  dateTime?: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!dateTime) {
    return '—';
  }
  return toLocaleTime(new Date(dateTime), options);
};

export const toLocaleDate = (
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) => {
  return date.toLocaleDateString(undefined, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    ...options,
  });
};

export const toLocaleTime = (
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) => {
  return date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    ...options,
  });
};

// 23:00 - 01:00 EEST
export const formatToDuration = (startTime: string, endTime: string) => {
  return `${formatToTime(startTime)} - ${formatToTime(endTime, {
    timeZoneName: 'short',
  })}`;
};

// Wed, 8 May 23:00 EEST
export const formatToSimpleDateTime = (dateTime?: string) => {
  if (!dateTime) {
    return '—';
  }
  const date = new Date(dateTime);
  const datePart = toLocaleDate(date, { year: undefined });
  const timePart = toLocaleTime(date, {
    timeZoneName: 'short',
  });
  return `${datePart} ${timePart}`;
};

export const isDateBefore = (date?: string, dateToCompare?: string) => {
  return date && dateToCompare
    ? isBefore(new Date(date), new Date(dateToCompare))
    : false;
};

export const isClassSubscriptionIncluded = (
  endTime?: string,
  program?: TProgram | null,
) => {
  if (!program || !program.publicEnrollmentEnabled) {
    return false;
  }
  return isDateBefore(endTime, program.endDate);
};

export const showToast = (
  message: string,
  type: 'error' | 'success',
  options?: ToastOptions,
) => {
  if (type === 'error') {
    toast.error(message, { position: 'bottom-center', ...options });
  } else {
    toast.success(message, { position: 'bottom-center', ...options });
  }
};

export const showPostInviteMessage = (resolvedInvite?: TResolvedInvite) => {
  if (
    !resolvedInvite ||
    !['privateClass', 'program'].includes(resolvedInvite.inviteType)
  ) {
    return;
  }

  if (resolvedInvite.errorCode) {
    const message = resolveApiMessage(resolvedInvite.errorCode);
    showToast(message, 'error', { duration: 20000 });
    return;
  }

  const { inviteType, itemName, inviteItemCreated } = resolvedInvite;

  if (!inviteItemCreated) {
    const message =
      inviteType === 'privateClass'
        ? `You are already enrolled in the class: ${itemName}!`
        : `You are already added to the program and may now proceed to enroll in courses!`;
    showToast(message, 'success', { duration: 20000 });
    return;
  }

  const message =
    inviteType === 'privateClass'
      ? `You have successfully enrolled in the class: ${itemName}!`
      : `You have been added to the program and may now proceed to enroll in courses!`;
  showToast(message, 'success', { duration: 12000 });
};

export const resolveApiMessage = (code: ErrorCode): string => {
  switch (code) {
    case ErrorCode.EMAIL_TAKEN:
      return 'The email address is already in use. If this is your account, please log in instead.';
    case ErrorCode.NAME_REQUIRED:
      return 'First name and last name are required.';
    case ErrorCode.LINK_INVALID:
      return 'The link is either invalid or has expired. Please request a new link.';
    case ErrorCode.PROGRAM_FULL:
      return 'The program is full, and you were not added. Please contact your manager.';
    case ErrorCode.CLASS_FULL:
      return 'The class is full, and you were not added. Please contact your manager.';
    case ErrorCode.CLASS_ENDED:
      return 'The class has already ended, and you were not added. Please contact your manager.';
    case ErrorCode.PERMISSION_DENIED:
      return 'You do not have the necessary permissions to perform this action.';
    case ErrorCode.NOT_FOUND:
      return 'The requested resource was not found.';
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
};

export const getUserNavigation = (user: IUser | null) => {
  const navigationPath = cloneDeep(NAVIGATION_INFO);

  if (user?.individual) {
    navigationPath.info.items = navigationPath.info.items.filter(
      (item) => ![PATH.companyInfo].includes(item.path),
    );
    navigationPath.settings.items = navigationPath.info.items.filter(
      (item) => ![PATH.companyInfo].includes(item.path),
    );
  }

  if (!user?.program?.privateClassesEnabled) {
    navigationPath.library.items = navigationPath.library.items.filter(
      (item) => ![PATH.privateCourses].includes(item.path),
    );
  }

  return navigationPath;
};

export const resolveTagClassName = (color: TColor) => {
  switch (color) {
    case 'base':
      return 'bg-transparent text-core-dark';
    case 'neutral':
      return 'bg-grey-50 text-core-dark';
    case 'green':
      return 'bg-green-500 text-green-900';
    case 'yellow':
      return 'bg-yellow-500 text-red-900';
    case 'danger':
      return 'bg-red-100 text-red-900';
    case 'red':
      return 'bg-red-500 text-core-dark';
    case 'primary':
      return 'bg-blue-100 text-blue-primary';
    case 'blue':
      return 'bg-blue-100 text-core-dark';
  }
};

export const userAvatar = (user?: IUser | null) => {
  return user?.photo || icons.avatarPlaceholder;
};

export const companyLogo = (company?: ICompany | null) => {
  return company?.photo || icons.companyPlaceholder;
};

export const getSubscriptionIcon = (program: TProgram) => {
  switch (program.type) {
    case ProgramType.TEAM_PASS:
      return icons.teamPass;
    case ProgramType.TEAM_PASS_PLUS:
      return icons.teamPassPlus;
    default:
      return undefined;
  }
};

// Wed, 8 May - Thu, 9 May 2024
export const formatClassDate = (
  startTime?: string,
  endTime?: string,
  includeTime: boolean = false,
) => {
  if (!startTime || !endTime) {
    return startTime ? formatToDate(startTime) : '—';
  }

  let formatted;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const sameDay = isSameDay(startDate, endDate);
  if (sameDay) {
    formatted = toLocaleDate(startDate);
  } else {
    const formattedStartDate = toLocaleDate(startDate, {
      year: undefined,
    });
    const formattedEndDate = toLocaleDate(endDate);
    formatted = `${formattedStartDate} - ${formattedEndDate}`;
  }

  if (includeTime) {
    const formattedStartTime = toLocaleTime(startDate);
    const formattedEndTime = toLocaleTime(endDate, {
      timeZoneName: 'short',
    });
    formatted = `${formatted}, ${formattedStartTime}-${formattedEndTime}`;
  }

  return formatted;
};

export const calculateTotalToPay = (amount: number, coupon?: TCoupon) => {
  let discount = 0;

  if (!coupon) {
    return { totalToPay: amount, discount };
  }

  if (coupon.percentOff) {
    discount = amount * (coupon.percentOff / 100);
  }
  if (coupon.amountOff) {
    discount = coupon.amountOff / 100;
  }

  const newAmount = Math.round(Math.max(amount - discount, 0.5) * 100) / 100;

  return { totalToPay: newAmount, discount };
};

export const getCroppedCanvas = (
  image: HTMLImageElement | null,
  crop?: Crop,
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx || !image || !crop) {
    console.error('No 2d context');
    return null;
  }

  const pixelCrop = convertToPixelCrop(crop, image.width, image.height);

  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';
  ctx.save();

  const cropX = pixelCrop.x * scaleX;
  const cropY = pixelCrop.y * scaleY;

  ctx.translate(-cropX, -cropY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );
  return canvas;
};

export const isCohortOrSeries = (programType?: string): boolean => {
  return programType === CourseType.cohort || programType === CourseType.series;
};
