import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { resolveApiMessage, showToast } from '../helpers';
import { upcomingClasses } from '../requests';
import type { AppDispatch, RootState } from '../store/store';
import { ErrorCode } from '../types';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDropdown = (
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = ({ target }: MouseEvent) => {
    if (!dropdownRef.current?.contains(target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen]);

  return {
    isOpen,
    setIsOpen,
    handleDropdownToggle,
    dropdownRef,
  };
};

export const useOverflowHidden = (isHidden: boolean) => {
  useEffect(() => {
    if (isHidden) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isHidden]);
};

export const useErrorRequestParam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');

  useEffect(() => {
    if (!error) {
      return;
    }

    showToast(resolveApiMessage(error as ErrorCode), 'error');
    searchParams.delete('error');
    navigate(location.pathname, { replace: true });
  }, [searchParams]);
};

export const useUpcomingClassesQuery = () => {
  return useQuery({
    queryKey: ['upcomingClasses'],
    queryFn: upcomingClasses,
  });
};
