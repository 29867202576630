import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import ClassCalendar from '../../../components/common/ClassCalendar';
import ConfirmationPopup from '../../../components/common/ConfirmationPopup';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import NavHeader from '../../../components/common/NavHeader';
import SubNavigation from '../../../components/common/SubNavigation';
import CourseViewSelector from '../../../components/common/library/CourseViewSelector';
import { formatClassDate, isClassSubscriptionIncluded } from '../../../helpers';
import { NAVIGATE_PATH, NAVIGATION_INFO } from '../../../helpers/constants';
import { icons } from '../../../helpers/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getCalendarEvents } from '../../../requests';
import {
  togglePrivateCourse,
  togglePublicCourse,
} from '../../../store/reducers/userSlice';
import { CalendarEventLibraryProps, TPublicCartItem } from '../../../types';

type TCalendarComponentEvent = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  editable?: boolean;
  display?: string;
  extendedProps: CalendarEventLibraryProps;
};

const LibraryCalendarView: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user, cart } = useAppSelector((state) => state.userReducer);

  const [popupOpen, setPopupOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState<TCalendarComponentEvent>();

  const { data: calendarEvents = [], isLoading } = useQuery({
    queryKey: ['calendarEvents'],
    queryFn: () => getCalendarEvents(),
  });

  const mappedEvents = useMemo(() => {
    return calendarEvents.map<TCalendarComponentEvent>((calendarEvent) => {
      const { availableTime, courseInfo } = calendarEvent;
      const availableTimeToBook =
        calendarEvent.availableTime.parentAvailableTime ||
        calendarEvent.availableTime;
      return {
        id: availableTime.id,
        title: availableTime.name,
        start: new Date(availableTime.startTime),
        end: new Date(availableTime.endTime),
        editable: false,
        display: 'list-item',
        extendedProps: {
          startTimeString: availableTime.startTime,
          endTimeString: availableTime.endTime,
          cartItem: {
            ...availableTimeToBook,
            course: {
              name: availableTimeToBook.name || courseInfo.name,
              imageUrl: courseInfo.imageUrl,
            },
            included: courseInfo.isPrivate
              ? true
              : isClassSubscriptionIncluded(
                  availableTimeToBook.endTime,
                  user?.program,
                ),
          },
          slug: courseInfo.slug,
          type: courseInfo.type,
          isBooked: availableTimeToBook.isBooked,
          isPrivate: courseInfo.isPrivate,
          isClassInCart: courseInfo.isPrivate
            ? cart.private.some((item) => item.id === availableTimeToBook.id)
            : cart.public.some((item) => item.id === availableTimeToBook.id),
        },
      };
    });
  }, [calendarEvents, user, cart]);

  return (
    <>
      <NavHeader label={<CourseViewSelector currentView="calendar" />} />
      {user && <SubNavigation items={NAVIGATION_INFO.library.items} />}
      {isLoading ? (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <ClassCalendar
          events={mappedEvents}
          onEventClick={(info) => {
            const event = info.event as unknown as TCalendarComponentEvent;
            if (event.extendedProps.isBooked) return;

            setActiveEvent(info.event as unknown as TCalendarComponentEvent);
            setPopupOpen(true);
          }}
        />
      )}
      {activeEvent && (
        <ConfirmationPopup
          title="Class Details"
          titleClassName="text-left text-lg text-core-dark font-bold"
          open={popupOpen}
          setOpen={setPopupOpen}
          cancelText="Go back"
          confirmText={
            activeEvent.extendedProps.isClassInCart
              ? 'Remove from cart'
              : 'Add to cart'
          }
          onConfirm={async () => {
            if (activeEvent.extendedProps.isPrivate) {
              dispatch(togglePrivateCourse(activeEvent.extendedProps.cartItem));
            } else {
              console.log(activeEvent.extendedProps.cartItem);

              dispatch(
                togglePublicCourse(
                  activeEvent.extendedProps.cartItem as TPublicCartItem,
                ),
              );
            }

            setPopupOpen(false);
            setActiveEvent(undefined);
          }}
        >
          <div className="flex flex-col gap-2 text-left">
            <div className="font-medium uppercase text-blue-primary text-regular">
              {formatClassDate(
                activeEvent.extendedProps.cartItem.startTime,
                activeEvent.extendedProps.cartItem.endTime,
                true,
              )}
            </div>
            <div className="flex justify-between gap-2">
              <div className="text-2xl whitespace-normal text-heading-dark">
                {activeEvent.extendedProps.cartItem.name}
              </div>
              {(activeEvent.extendedProps.isPrivate ||
                !!activeEvent.extendedProps.slug) && (
                <Link
                  className="self-start shrink-0"
                  to={
                    activeEvent.extendedProps.isPrivate
                      ? NAVIGATE_PATH.privateCourses
                      : NAVIGATE_PATH.publicCourse.replace(
                          ':slug',
                          activeEvent.extendedProps.slug as string,
                        )
                  }
                >
                  <img src={icons.externalLink} alt="open course page" />
                </Link>
              )}
            </div>
          </div>
        </ConfirmationPopup>
      )}
    </>
  );
};

export default LibraryCalendarView;
