import { useQuery } from '@tanstack/react-query';
import ContentLoader from 'react-content-loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { Link, Navigate } from 'react-router-dom';

import { ReactComponent as CopyIcon } from '../../../assets/images/copy.svg';
import NavHeader from '../../../components/common/NavHeader';
import ImageControl from '../../../components/common/settings/ImageControl';
import ShortToast from '../../../components/common/toasts/ShortToast';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { useAppSelector } from '../../../hooks/hooks';
import { getCompany } from '../../../requests';
import { UserRole } from '../../../models/IUser';

const CompanySettings = () => {
  const { user, isManager } = useAppSelector((state) => state.userReducer);

  const { data: company, isLoading } = useQuery({
    queryKey: ['companyDetails'],
    queryFn: getCompany,
  });

  const handleCopy = () => {
    toast.custom(
      <ShortToast
        text="Copied to clipboard"
        icon={<CopyIcon fill="#223BF4" />}
      />,
    );
  };

  if (user?.individual) {
    return <Navigate to={NAVIGATE_PATH.profileSettings} replace />;
  }

  return (
    <>
      <NavHeader label={`Company ${isManager ? 'Settings' : 'Info'}`} />
      <div className="main-container w-full pt-4 gap-2 max-w-[54rem] ">
        {isManager && (
          <div className="pb-3 font-medium text-regular text-core-dark">
            Manage your organization settings below
          </div>
        )}
        <div className="settings-header">{user?.company.name}</div>
        <table className="settings-table">
          <tbody>
            <tr>
              <td>Company Logo</td>
              <td>
                <ImageControl
                  src={user?.company.photo}
                  type={'company'}
                  editable={isManager}
                />
              </td>
            </tr>
            <tr>
              <td>Company Name</td>
              <td className="w-full text-black rounded bg-grey-50">
                {user?.company.name}
              </td>
            </tr>
            {(user?.role === UserRole.MANAGER ||
              user?.role === UserRole.ADMIN) && (
              <tr>
                <td>Admin Portal</td>
                <td className="w-full">
                  <a
                    href="https://www.learnitadminportal.com/s/login"
                    className="underline-link"
                    target="_blank"
                  >
                    Log in to Admin Portal
                  </a>
                </td>
              </tr>
            )}

            {!isLoading && (
              <>
                <tr>
                  <td>Total members</td>
                  <td className="w-full">
                    <div className="flex justify-between">
                      <span>{company?.totalMembers}</span>
                      <Link
                        className="hidden underline-link"
                        to={NAVIGATE_PATH.teamMembers}
                      >
                        View members
                      </Link>
                    </div>
                  </td>
                </tr>
                {company?.inviteLink && (
                  <tr>
                    <td>Team invite url</td>
                    <td className="w-full">
                      <div className="flex items-center justify-between gap-4 text-grey-700">
                        <div>{company?.inviteLink}</div>
                        <div className="cursor-pointer">
                          <CopyToClipboard
                            text={company?.inviteLink ?? ''}
                            onCopy={() => handleCopy()}
                          >
                            <CopyIcon
                              width="1.5rem"
                              height="1.5rem"
                              fill="#4A4A68"
                            />
                          </CopyToClipboard>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {company?.primaryContact && (
                  <>
                    <tr className="border-t border-grey-100"></tr>
                    <tr>
                      <td>Company Contact</td>
                      <td className="text-black">
                        {company.primaryContact.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Contact Email</td>
                      <td className="text-black">
                        {company.primaryContact.email}
                      </td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td className="text-core-dark">
                        {company.primaryContact.phone}
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        {isLoading && (
          <ContentLoader width="100%" height="200px" className="rounded-lg">
            <rect x="0" y="0" rx="10" ry="10" width="152" height="100%" />
            <rect x="160" y="0" rx="10" ry="10" width="100%" height="100%" />
          </ContentLoader>
        )}
      </div>
    </>
  );
};

export default CompanySettings;
