import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

import { NAVIGATE_PATH as PATH } from '../../../../helpers/constants';
import { icons } from '../../../../helpers/icons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { validateCoupon } from '../../../../requests';
import { setActiveCoupon } from '../../../../store/reducers/userSlice';
import { TCartItem, TCoupon } from '../../../../types';
import { ButtonRegular } from '../../buttons/ButtonRegular';
import Delimiter from '../../Delimiter';
import SubscriptionTag from '../SubscriptionTag';
import SummaryQuantity from './SummaryQuantity';
import { calculateTotalToPay } from '../../../../helpers';

interface IOrderSummaryCard {
  buttonHidden?: boolean;
  nextStepDisabled?: boolean;
  checkoutItems?: TCartItem[];
  finalized?: {
    totalPayed: number;
    appliedCoupon?: TCoupon;
  };
}

const OrderSummaryCard: React.FC<IOrderSummaryCard> = ({
  buttonHidden = false,
  nextStepDisabled = false,
  checkoutItems,
  finalized,
}) => {
  const dispatch = useAppDispatch();
  const { user, cart, cartTotal, cartTotalToPay } = useAppSelector(
    (state) => state.userReducer,
  );
  const [open, setOpen] = useState(false);
  const [couponError, setCouponError] = useState<string>();

  const handleCouponSubmit = async (values: { coupon: string }) => {
    try {
      const response = await validateCoupon(values.coupon, cartTotalToPay);
      if (response.metadata.couponUsed) {
        setCouponError('');
        dispatch(setActiveCoupon(response));
      } else {
        formik.resetForm();
        setCouponError(response.metadata.reason);
      }
    } catch (error) {
      setCouponError('An unknown error has occurred. Please try again');
    }
  };

  const formik = useFormik({
    initialValues: {
      coupon: '',
    },
    onSubmit: handleCouponSubmit,
  });

  const items =
    checkoutItems ??
    (cart.status === 'second_step'
      ? [...cart.processed, ...cart.public]
      : [...cart.public, ...cart.private]);

  const { totalToPay, discount } = calculateTotalToPay(
    finalized?.totalPayed ?? cartTotalToPay,
    finalized?.appliedCoupon ?? cart.coupon,
  );

  const coupon = cart.coupon ?? finalized?.appliedCoupon;

  const totalQuantity = items.reduce(
    (acc, { studentIds }) => acc + (studentIds?.length || 1),
    0,
  );
  const paidQuantity = items.reduce(
    (acc, { included, studentIds }) =>
      included ? acc : acc + (studentIds?.length || 1),
    0,
  );

  const renderButton = () => {
    if (buttonHidden) {
      return null;
    }

    if (
      (!cart.public.length && !cart.private.length) ||
      nextStepDisabled ||
      formik.isSubmitting
    ) {
      return (
        <ButtonRegular
          text="Go to checkout"
          className="self-center button-primary"
          disabled
        />
      );
    }

    if (formik.values.coupon && !couponError && !coupon) {
      return (
        <ButtonRegular
          onClick={formik.submitForm}
          text="Go to checkout"
          className="self-center button-primary"
        />
      );
    }

    if (!user) {
      return (
        <ButtonRegular
          type="submit"
          text="Go to checkout"
          className="self-center button-primary"
        />
      );
    }

    return (
      <Link to={PATH.firstCheckoutStep} className="flex justify-center">
        <ButtonRegular text="Go to checkout" className="button-primary" />
      </Link>
    );
  };

  return (
    <div
      className={`side-info-card max-xl:mobile-order-card xl:sticky xl:top-16
      max-xl:guest:mobile-order-card max-xl:guest:-bottom-4`}
    >
      <div
        className="flex flex-col justify-center gap-2 pt-6 cursor-pointer xl:hidden max-xl:guest:flex xl:guest:hidden touch-pan-x"
        onClick={() => setOpen(!open)}
        onTouchStart={() => setOpen(!open)}
      >
        <img className="self-center" src={icons.drag} alt="" />
        <div className="font-bold text-center uppercase text-regular text-blue-primary">
          Order summary & Check out
        </div>
      </div>
      <div
        className={`flex flex-col gap-4 text-core-dark overflow-hidden
           transition-max-height ease-in-out duration-700 ${
             open
               ? 'max-xl:max-h-[50vh] max-xl:guest:max-h-[50vh]'
               : 'max-xl:max-h-0 max-xl:guest:max-h-0'
           }`}
      >
        <div className="font-bold uppercase max-xl:hidden text-regular text-grey-700">
          Order summary
        </div>
        <div className="flex flex-col gap-2 p-2 bg-white rounded text-regular">
          {user?.program && (
            <div className="flex items-center justify-between">
              <div>Default voucher connected</div>
              <SubscriptionTag />
            </div>
          )}
          {user && (
            <div className="flex items-center justify-between gap-4">
              <div className="whitespace-nowrap">Org name</div>
              <div className="font-bold text-right">{user.company.name}</div>
            </div>
          )}
          {user?.program && (
            <SummaryQuantity
              label={`${user.program.type} classes`}
              quantity={totalQuantity - paidQuantity}
              booked={!!finalized || cart.status === 'second_step'}
            />
          )}
          <SummaryQuantity
            label="Paid classes"
            quantity={paidQuantity}
            booked={!!finalized}
          />
        </div>
        {!finalized && (
          <div className="flex justify-between px-2 text-2xl font-bold">
            <div>Total</div>
            <div>${cartTotal.toFixed(2)}</div>
          </div>
        )}
        <Delimiter className="!bg-core-dark" />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <span className="font-bold uppercase text-grey-700 text-regular">
              Coupon code
            </span>
            {couponError && (
              <span className="text-xs text-red-700">{couponError}</span>
            )}
          </div>

          {coupon ? (
            <div className="flex items-start gap-4">
              <div className="grow">
                <p className="font-bold text-blue-primary text-regular">
                  {coupon.code}
                </p>
                <p className="text-regular text-core-dark">
                  {coupon.percentOff && `${coupon.percentOff}% `}
                  {coupon.amountOff && `$${coupon.amountOff / 100} `}- discount
                  applied successfully
                </p>
              </div>
              <div>
                <span className="font-bold text-regular text-core-dark whitespace-nowrap">
                  -${discount.toFixed(2)}
                </span>
              </div>
              {!finalized && (
                <div className="w-[22px] h-[22px] shrink-0 cursor-pointer">
                  <img
                    src={icons.trash}
                    alt="remove coupon"
                    className="w-full h-full"
                    onClick={() => dispatch(setActiveCoupon(undefined))}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center h-10 bg-white rounded">
              <input
                type="text"
                name="coupon"
                maxLength={32}
                autoComplete="off"
                value={formik.values.coupon}
                className="w-full h-full p-2 font-medium text-core-dark text-regular placeholder:text-grey-500"
                placeholder="Enter coupon code here"
                onChange={formik.handleChange}
              />

              <button
                className={`uppercase px-2 font-bold text-xs ${
                  formik.isSubmitting ? 'text-blue-primary' : 'text-gray-700'
                }`}
                onClick={formik.submitForm}
              >
                {formik.isSubmitting ? 'Applying...' : 'Apply'}
              </button>
            </div>
          )}
        </div>
        <Delimiter className="!bg-core-dark" />
        <div className="flex justify-between px-2 text-2xl font-bold">
          <div>{finalized ? 'Paid' : 'To pay'}</div>
          <div className="text-2xl text-blue-primary">
            ${totalToPay.toFixed(2)}
          </div>
        </div>
        {renderButton()}
      </div>
    </div>
  );
};

export default OrderSummaryCard;
