import React from 'react';

import Delimiter from '../../Delimiter';
import { useAppSelector } from '../../../../hooks/hooks';
import { formatClassDate } from '../../../../helpers';
import { TCartItem } from '../../../../types';

interface ICheckoutList {
  subscriptionIncluded?: boolean;
}

const CheckoutList: React.FC<ICheckoutList> = ({
  subscriptionIncluded = false,
}) => {
  const { cart, user } = useAppSelector((state) => state.userReducer);

  const renderRow = (
    id: string,
    name: string,
    startTime: string,
    endTime: string,
  ) => {
    return (
      <div
        key={id}
        className="flex flex-col justify-between pb-2 border-b border-gray-100 md:flex-row lg:flex-col 2xl:flex-row last:border-b-0 last:pb-0"
      >
        <p className="flex-[1.3_1.3_0%] font-bold pr-1">{name}</p>
        <p className="flex-1 text-sm">
          {formatClassDate(startTime, endTime, true)}
        </p>
      </div>
    );
  };

  const renderItem = (item: TCartItem) => {
    if (item.subItems) {
      return item.subItems.map((subItem) =>
        renderRow(subItem.id, subItem.name, subItem.startTime, subItem.endTime),
      );
    }

    return renderRow(item.id, item.course.name, item.startTime, item.endTime);
  };

  const renderItemGroup = (items: TCartItem[], header: string) => {
    if (!items.length) {
      return null;
    }
    return (
      <div className="flex flex-col gap-2">
        <p className="font-bold text-regular">{header}</p>
        <div className="flex flex-col rounded md:gap-1">
          {items.map(renderItem)}
        </div>
      </div>
    );
  };

  const publicItems = subscriptionIncluded
    ? cart.public.filter((item) => item.included)
    : cart.public;

  return (
    <div className="flex flex-col font-medium text-regular text-core-dark">
      {renderItemGroup(cart.private, 'Private classes')}
      {cart.private.length > 0 && publicItems.length > 0 && (
        <Delimiter className="my-4 bg-grey-200"></Delimiter>
      )}
      {renderItemGroup(publicItems, 'Public classes')}
      {subscriptionIncluded && publicItems.length > 0 && user?.program && (
        <div className="mt-2">
          You are eligible for {user?.program.type} subscription type with
          Learnit which means the total price for public classes below is
          <span className="font-bold"> USD 0.00</span>
        </div>
      )}
    </div>
  );
};

export default CheckoutList;
