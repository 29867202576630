import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TActiveFilters,
  TCourseFilters,
  TCourseGroup,
  TCoursePreview,
  TNamedItem,
} from '../../types';

interface LibraryState {
  allCourses: TCourseGroup[];
  filteredCourses: TCourseGroup[];
  filters: TCourseFilters;
  secondaryFilters: TNamedItem[];
  activeFilters: TActiveFilters;
}

const initialState: LibraryState = {
  allCourses: [],
  filteredCourses: [],
  secondaryFilters: [],
  filters: {
    parentCategories: [],
    categories: [],
    competencies: [],
  },
  activeFilters: {
    primaryFilter: null,
    secondaryFilter: null,
  },
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setCourses(state, { payload }: PayloadAction<TCourseGroup[]>) {
      state.allCourses = payload;
      state.filteredCourses = filterCourseGroups(state);
    },
    setCourseFilters(state, { payload }: PayloadAction<TCourseFilters>) {
      state.filters = payload;
      if (!state.secondaryFilters.length) {
        state.secondaryFilters = payload.competencies;
      }
    },
    setSecondaryFilters(state, { payload }: PayloadAction<TNamedItem>) {
      if (payload.id == state.filters.parentCategories[0].id) {
        state.secondaryFilters = state.filters.competencies;
      } else {
        state.secondaryFilters = state.filters.categories;
      }
    },
    filterCourses(state, { payload }: PayloadAction<Partial<TActiveFilters>>) {
      state.activeFilters = { ...state.activeFilters, ...payload };
      state.filteredCourses = filterCourseGroups(state);
    },
  },
});

const filterCourseGroups = (state: LibraryState) => {
  const sorted = state.allCourses
    .reduce((acc: TCourseGroup[], courseGroup) => {
      const filteredCourses = courseGroup.courses.filter((course) =>
        coursePredicate(course, state.activeFilters),
      );
      if (filteredCourses.length) {
        acc.push({
          groupName: courseGroup.groupName,
          courses: filteredCourses,
        });
      }
      return acc;
    }, [])
    .sort((a, b) => b.courses.length - a.courses.length);

  if (state.activeFilters.secondaryFilter) {
    const index = sorted.findIndex(
      (group) => group.groupName === state.activeFilters.secondaryFilter?.name,
    );
    if (index > 0) {
      sorted.unshift(sorted.splice(index, 1)[0]);
    }
  }

  return sorted;
};

const coursePredicate = (
  course: TCoursePreview,
  activeFilters: TActiveFilters,
) => {
  let matches = true;
  if (activeFilters.primaryFilter) {
    matches &&= course.categoryIds?.includes(activeFilters.primaryFilter.id);
  }
  if (activeFilters.secondaryFilter) {
    matches =
      course.categoryIds?.includes(activeFilters.secondaryFilter.id) ||
      course.skillIds?.includes(activeFilters.secondaryFilter.id);
  }
  return matches;
};

export const {
  setCourses,
  setCourseFilters,
  setSecondaryFilters,
  filterCourses,
} = librarySlice.actions;
export default librarySlice.reducer;
