import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { subDays } from 'date-fns';

import Delimiter from '../../../components/common/Delimiter';
import NavHeader from '../../../components/common/NavHeader';
import SubNavigation from '../../../components/common/SubNavigation';
import ClassRow from '../../../components/common/dashboard/ClassRow';
import ClassRowSkeleton from '../../../components/common/dashboard/ClassRowSkeleton';
import ClassesHeader from '../../../components/common/dashboard/ClassesHeader';
import ClassesNotFound from '../../../components/common/dashboard/ClassesNotFound';
import EvaluationButton from '../../../components/common/dashboard/EvaluationButton';
import { NAVIGATION_INFO } from '../../../helpers/constants';
import { pastClasses } from '../../../requests';

const PastClasses = () => {
  const [period, setPeriod] = useState(null);

  const { data: classes = [], isLoading } = useQuery({
    queryKey: ['pastClasses', period],
    queryFn: ({ signal }) =>
      pastClasses(
        period ? subDays(new Date(), period).toISOString() : undefined,
        signal,
      ),
  });

  return (
    <>
      <NavHeader label="Your past classes" />
      <SubNavigation
        items={NAVIGATION_INFO.dashboard.items}
        select={{
          options: [
            { text: 'All', value: null },
            { text: 'Last week', value: 7 },
            { text: 'Last month', value: 30 },
            { text: 'Last 6 months', value: 180 },
          ],
          active: period,
          setActive: setPeriod,
        }}
      />
      <div className="flex flex-col">
        {classes.length === 0 && !isLoading ? (
          <div className="px-4 lg:px-8">
            <ClassesNotFound title="You have no past classes attended" />
          </div>
        ) : (
          <>
            <ClassesHeader lastRowHeader="Class evaluation" />
            <Delimiter />
            {isLoading && <ClassRowSkeleton />}
            {classes.map((classInfo) => (
              <ClassRow key={classInfo.id} classInfo={classInfo} type="past">
                {classInfo.evaluationLink && (
                  <EvaluationButton
                    evaluationLink={classInfo.evaluationLink}
                    isEvaluated={classInfo.isEvaluated}
                  />
                )}
              </ClassRow>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default PastClasses;
