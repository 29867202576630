import React, { ReactNode, useState } from 'react';

import Credentials from '../Credentials';
import ClassDetails from '../ClassDetails';
import ClassAvatar from '../ClassAvatar';
import Delimiter from '../../Delimiter';
import { TClass } from '../../../../types';
import ClassStatus from '../ClassStatus';
import { icons } from '../../../../helpers/icons';
import ActionMenu from '../../ActionMenu';
import ClassCancellationPopup from '../../../popups/ClassCancellationPopup';
import { useAppSelector } from '../../../../hooks/hooks';
import { UserRole } from '../../../../models/IUser';

interface IClassRow {
  type: 'upcoming' | 'past';
  classInfo: TClass;
  children: ReactNode;
}

const ClassRow: React.FC<IClassRow> = ({ type, classInfo, children }) => {
  const { user } = useAppSelector((state) => state.userReducer);
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      <div className="class-row group/class">
        <div className="flex flex-col items-start gap-2 class-first-column">
          {classInfo.credentials?.roomEmail && (
            <Credentials
              username={classInfo.credentials.roomEmail}
              password={classInfo.credentials.roomPassword}
            />
          )}
          <ClassDetails {...classInfo} />
        </div>
        <div className="class-column-group">
          <div className="flex items-center justify-center class-second-column">
            <ClassStatus
              attended={classInfo.attended}
              status={classInfo.status}
              startTime={classInfo.startTime}
              endTime={classInfo.endTime}
            />
          </div>
          <div className="flex items-center class-third-column">
            {classInfo.companyName ? (
              <ClassAvatar
                name={classInfo.companyName}
                photo={classInfo.companyPhoto || icons.companyPlaceholder}
              />
            ) : (
              classInfo.type
            )}
          </div>
          <div className="flex items-center justify-center class-fourth-column">
            {children}
          </div>
          {type === 'upcoming' && (
            <div className="absolute right-0 pr-8 max-xl:mt-20 group-hover/class:block">
              <ActionMenu
                button={<img src={icons.options} alt="" className="shrink-0" />}
                items={[
                  {
                    label: 'Request Cancel Class',
                    onClick: () => setPopupOpen(true),
                    hidden: user?.role === UserRole.INSTRUCTOR,
                  },
                  {
                    label: 'Add to Calendar',
                    link: `${process.env.REACT_APP_API_HOST}/ical/${classInfo.sfEventId}`,
                  },
                ]}
              />
              <ClassCancellationPopup
                open={popupOpen}
                setOpen={setPopupOpen}
                classInfo={classInfo}
              />
            </div>
          )}
        </div>
      </div>
      <div className="px-4 lg:px-8">
        <Delimiter />
      </div>
    </>
  );
};

export default ClassRow;
