import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  removePrivateCourse,
  removePublicCourse,
} from '../../../../../store/reducers/userSlice';
import CartItem from './CartItem';

const CartItems: React.FC = () => {
  const dispatch = useAppDispatch();
  const { cart } = useAppSelector((state) => state.userReducer);

  if (cart.public.length + cart.private.length == 0) {
    return null;
  }

  return (
    <div className="max-h-[30vh] flex flex-col gap-2 overflow-y-auto">
      {cart.private.map((item) => (
        <CartItem
          key={item.id}
          item={item}
          onRemove={() => dispatch(removePrivateCourse(item))}
          isPrivate
        />
      ))}
      {cart.public.map((item) => (
        <CartItem
          key={item.id}
          item={item}
          price={item.price}
          onRemove={() => dispatch(removePublicCourse(item))}
        />
      ))}
    </div>
  );
};

export default CartItems;
