import React, { useMemo } from 'react';

import ClassCalendar from '../../../components/common/ClassCalendar';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import NavHeader from '../../../components/common/NavHeader';
import SubNavigation from '../../../components/common/SubNavigation';
import { NAVIGATION_INFO } from '../../../helpers/constants';
import { useAppSelector, useUpcomingClassesQuery } from '../../../hooks/hooks';
import { CalendarEventBasicProps } from '../../../types';

type TCalendarComponentEvent = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  editable?: boolean;
  display?: string;
  extendedProps: CalendarEventBasicProps;
};

const DashboardCalendarView: React.FC = () => {
  const { user } = useAppSelector((state) => state.userReducer);

  const { data: classes = [], isLoading } = useUpcomingClassesQuery();

  const mappedEvents = useMemo(() => {
    return classes.map<TCalendarComponentEvent>((classInfo) => {
      return {
        id: classInfo.id,
        title: classInfo.name,
        start: new Date(classInfo.startTime),
        end: new Date(classInfo.endTime),
        editable: false,
        display: 'list-item',
        extendedProps: {
          startTimeString: classInfo.startTime,
          endTimeString: classInfo.endTime,
          type: classInfo.courseType,
          isBooked: true,
        },
      };
    });
  }, [classes, user]);

  return (
    <>
      <NavHeader label={'Your upcoming classes'} />
      <SubNavigation items={NAVIGATION_INFO.dashboard.items} />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <ClassCalendar events={mappedEvents} />
      )}
    </>
  );
};

export default DashboardCalendarView;
