import axiosInstance from '../helpers/axios';
import { ICompanyInfo } from '../models/ICompany';
import { IUser } from '../models/IUser';
import {
  TAvailableModule,
  TCalendarEvent,
  TCart,
  TCheckoutPayment,
  TClass,
  TCouponValidation,
  TCourse,
  TCourseFilters,
  TCourseGroup,
  TInitialCheckoutResult,
  TInviteDetails,
  TInviteRequest,
  TInviteResponse,
  TLogin,
  TPrivateCourse,
  TProgramInvite,
  TProgramInviteValues,
  TUploadResponse,
} from '../types';

export const getInviteDetails = async (token?: string) => {
  const params = token ? { token } : null;
  const response = await axiosInstance.get<TInviteDetails>('invite-details', {
    params,
  });
  return response.data;
};

export const acceptInvite = async (data: TInviteRequest) => {
  const response = await axiosInstance.post<TInviteResponse>(
    'invites/accept',
    data,
  );
  return response.data;
};

export const signUp = async (userInfo: Partial<IUser>) => {
  const response = await axiosInstance.post<{ email?: string }>(
    'sign-up',
    userInfo,
  );
  return response.data;
};

export const setPassword = async (password: string, token?: string) => {
  const response = await axiosInstance.post<TLogin>('set-password', {
    token,
    password,
  });
  return response.data;
};

export const login = async (email: string, password?: string) => {
  const response = await axiosInstance.post<Partial<TLogin>>('login', {
    email,
    password,
  });
  return response.data;
};

export const logout = async () => {
  return axiosInstance.post('sign-out');
};

export const getMe = async () => {
  const response = await axiosInstance.get<IUser>('me');
  return response.data;
};

export const upcomingClasses = async () => {
  const response = await axiosInstance.get<TClass[]>('classes/upcoming');
  return response.data;
};

export const pastClasses = async (
  fromDate: string | undefined,
  signal: AbortSignal,
) => {
  const response = await axiosInstance.get<TClass[]>('classes/past', {
    params: { fromDate },
    signal,
  });
  return response.data;
};

export const getCompany = async () => {
  const response = await axiosInstance.get<ICompanyInfo>('companies');
  return response.data;
};

export const getCalendarEvents = async (params?: { bookedOnly: boolean }) => {
  const response = await axiosInstance.get<TCalendarEvent[]>(
    'courses/calendar-events',
    { params },
  );
  return response.data;
};

export const getCourseFilters = async () => {
  const response = await axiosInstance.get<TCourseFilters>('webflow/filters');
  return response.data;
};

export const getPublicCourses = async () => {
  const response = await axiosInstance.get<TCourseGroup[]>('webflow/courses');
  return response.data;
};

export const getPrivateCourses = async () => {
  const response = await axiosInstance.get<TPrivateCourse[]>('courses/private');
  return response.data;
};

export const getPublicCourse = async (slug?: string) => {
  const response = await axiosInstance.get<TCourse>(`webflow/courses/${slug}`);
  return response.data;
};

export const getCourseAvailableTimes = async (parentCourseId: string) => {
  const response = await axiosInstance.get<TAvailableModule[]>(
    `courses/${parentCourseId}/available-times`,
  );
  return response.data;
};

export const validateCoupon = async (couponCode: string, amount: number) => {
  const response = await axiosInstance.post<TCouponValidation>(
    'checkout/validate-coupon',
    { couponCode, amount },
  );
  return response.data;
};

export const checkout = async (cart: TCart) => {
  const response = await axiosInstance.post<TInitialCheckoutResult>(
    'checkout',
    cart,
  );
  return response.data;
};

export const createPaymentIntent = async (checkout: TCheckoutPayment) => {
  const response = await axiosInstance.post<{ clientSecret: string }>(
    `checkout/create-payment-intent`,
    checkout,
  );
  return response.data;
};

export const markAttendanceForClass = async (registrationId: string) => {
  await axiosInstance.post(`classes/${registrationId}/attend`);
};

export const requestClassCancel = async (registrationId: string) => {
  await axiosInstance.post(`classes/${registrationId}/request-cancel`);
};

export const getProgramInvites = async () => {
  const response =
    await axiosInstance.get<TProgramInvite[]>('invites/programs');
  return response.data;
};

export const deleteInvite = async (id: string) => {
  return await axiosInstance.delete(`invites/${id}`);
};

export const generateProgramInvite = async (data: TProgramInviteValues) => {
  const response = await axiosInstance.post<TProgramInvite>('invites', data);
  return response.data;
};

async function uploadImage(url: string, image: Blob | null) {
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
  const response = await axiosInstance.post<TUploadResponse>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const uploadCompanyLogo = async (image: Blob | null) => {
  return await uploadImage('companies/logo', image);
};

export const uploadUserAvatar = async (image: Blob | null) => {
  return await uploadImage('users/avatar', image);
};
