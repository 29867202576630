import React, { Fragment, ReactNode } from 'react';

import { Menu, Transition } from '@headlessui/react';

import { ActionMenuItem } from '../../../types';

interface IActionMenu {
  button: ReactNode;
  items: ActionMenuItem[];
}

const ActionMenu: React.FC<IActionMenu> = ({ button, items }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded hover:bg-grey-100 ui-open:bg-grey-100">
          {button}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 origin-top-right bg-white rounded-lg shadow-light">
          <div className="p-2">
            {items.map((item, index) => {
              if (item.hidden) {
                return null;
              }

              if (item.link) {
                return (
                  <a
                    key={index}
                    href={item.link}
                    className="w-full text-main dropdown-item whitespace-nowrap"
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.label}
                  </a>
                );
              }

              return (
                <Menu.Item key={index}>
                  <button
                    className="w-full text-main dropdown-item whitespace-nowrap"
                    onClick={item.onClick}
                  >
                    {item.label}
                  </button>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ActionMenu;
