import React from 'react';

import { Tooltip } from 'react-tooltip';

import CheckoutItemAssignment from './CheckoutItemAssignment';
import CheckoutItemPreview from './CheckoutItemPreview';
import CheckoutTag from '../../../CheckoutTag';
import Delimiter from '../../../../Delimiter';
import Price from '../../../Price';
import Quantity from '../Quantity';
import SubscriptionTag from '../../../SubscriptionTag';
import { formatToDate } from '../../../../../../helpers';
import { icons } from '../../../../../../helpers/icons';
import { TCartItem, TStudent } from '../../../../../../types';
import { useAppSelector } from '../../../../../../hooks/hooks';

interface ICheckoutItem {
  item: TCartItem;
  students?: TStudent[] | null;
  isPrivate?: boolean;
  price?: number;
  onRemove: () => void;
}

const CheckoutItem: React.FC<ICheckoutItem> = ({
  item,
  students,
  isPrivate = false,
  price,
  onRemove,
}) => {
  const { user } = useAppSelector((state) => state.userReducer);

  const renderTag = (isMobile: boolean = false) => {
    if (isPrivate) {
      return <CheckoutTag text="Private" color="neutral" />;
    }
    if (!user?.program) {
      return null;
    }
    if (item.included) {
      return <SubscriptionTag active />;
    }

    const message = user?.program.publicEnrollmentEnabled
      ? `NOT included with ${user?.program.type} as subscription ends`
      : `NOT included with ${user?.program.type} as public enrollment is disabled for this program`;
    const endDate = formatToDate(user?.program?.endDate);
    const showDate = user?.program.publicEnrollmentEnabled;

    return (
      <>
        <div
          data-tooltip-id="team-pass-tooltip"
          data-tooltip-html={
            showDate ? message + ` <strong>${endDate}</strong>` : message
          }
          data-tooltip-place="bottom"
        >
          <SubscriptionTag active={item.included} />
        </div>
        {isMobile ? (
          <div className="max-w-[20rem]">
            {message} {showDate && <span className="font-bold">{endDate}</span>}
          </div>
        ) : (
          <Tooltip id="team-pass-tooltip" className="tooltip max-w-[14rem]" />
        )}
      </>
    );
  };

  return (
    <div>
      <div className="flex flex-col justify-between gap-2 pt-4 font-medium 2xl:gap-0 2xl:flex-row text-regular text-core-dark">
        <div className="flex justify-between flex-grow gap-2">
          <CheckoutItemPreview item={item} />
          <div className="flex items-center justify-end grow shrink-0">
            <div className="max-2xl:hidden">{renderTag()}</div>
            <div
              className="md:w-full min-w-[4rem] 3xl:min-w-[8rem] flex basis-16
              justify-end 2xl:justify-center items-center"
            >
              <Quantity item={item} />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start justify-center pr-2 2xl:hidden text-regular text-grey-700">
            {renderTag(true)}
          </div>
          <div className="sm:min-w-[8rem] flex shrink-0 justify-end items-center">
            <Price
              price={price}
              quantity={item.studentIds?.length || 1}
              isPrivate={isPrivate}
              discounted={item.included}
            />
            <div className="p-2 pr-0 cursor-pointer" onClick={onRemove}>
              <img src={icons.close} alt="" />
            </div>
          </div>
        </div>
      </div>
      {students && <CheckoutItemAssignment item={item} students={students} />}
      <Delimiter className="mt-4 bg-grey-200" />
    </div>
  );
};

export default CheckoutItem;
