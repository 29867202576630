import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '../../../../assets/images/calendar.svg';
import { ReactComponent as ListIcon } from '../../../../assets/images/list.svg';
import IconButton from '../../buttons/IconButton';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

interface ICourseViewSelector {
  currentView: 'list' | 'calendar';
}

const CourseViewSelector: React.FC<ICourseViewSelector> = ({ currentView }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-4">
      <IconButton
        text="Course List"
        icon={ListIcon}
        active={currentView === 'list'}
        onClick={() => navigate(NAVIGATE_PATH.publicCourses)}
      />
      <IconButton
        text="Calendar View"
        icon={CalendarIcon}
        active={currentView === 'calendar'}
        onClick={() => navigate(NAVIGATE_PATH.libraryCalendar)}
      />
    </div>
  );
};

export default CourseViewSelector;
