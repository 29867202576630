import { EventClickArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import { tippy } from '@tippyjs/react';
import React from 'react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { useMediaQuery } from 'usehooks-ts';

import { formatToDuration } from '../../../helpers';
import { CourseType } from '../../../types';

interface IClassCalendar {
  events: EventInput[];
  onEventClick?: (event: EventClickArg) => void;
}

const ClassCalendar: React.FC<IClassCalendar> = ({ events, onEventClick }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className="flex flex-col min-h-screen pb-40 guest:guest-wrapper guest:mx-auto">
      <div className="relative">
        <div className="absolute inset-0 flex items-center justify-center gap-1 m-auto text-xs font-medium text-black top-11">
          <div className="flex items-center gap-1">
            <span className="w-2 h-2 bg-blue-primary"></span>
            <span>Full-day</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="w-2 h-2 rounded-full bg-blue-primary"></span>
            <span>Modules</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="w-0 h-0 border-l-[4px] border-r-[4px] border-b-[8px] border-transparent border-b-blue-primary"></span>
            <span>Cohorts/Series</span>
          </div>
        </div>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        initialView={isMobile ? 'listWeek' : 'dayGridMonth'}
        headerToolbar={{
          left: 'dayGridMonth listWeek',
          center: 'title',
          right: 'prev today next',
        }}
        dayMaxEvents={true}
        views={{
          dayGridMonth: {
            dayMaxEvents: 4,
          },
        }}
        eventInteractive={false}
        eventClick={onEventClick}
        events={events}
        eventColor="#384ff5"
        eventDisplay="list-item"
        eventClassNames={function (arg) {
          const classNames = ['cursor-pointer text-sm'];

          if (arg.view.type !== 'listWeek') {
            classNames.push('text-xs');
          }

          if (arg.event.extendedProps.isClassInCart) {
            classNames.push('bg-blue-100 text-blue-primary');
          }

          if (arg.event.extendedProps.isBooked) {
            classNames.push('opacity-50 hover:bg-transparent');
          }

          return classNames;
        }}
        eventDidMount={(mountArg) => {
          const dotSpan = mountArg.el.querySelector<HTMLElement>(
            mountArg.view.type === 'listWeek'
              ? '.fc-list-event-dot'
              : '.fc-daygrid-event-dot',
          );

          if (dotSpan) {
            // square
            if (mountArg.event.extendedProps.type === CourseType.fullDay) {
              dotSpan.style.borderRadius = 'unset';
            }

            // triangle
            if (
              mountArg.event.extendedProps.type === CourseType.cohort ||
              mountArg.event.extendedProps.type === CourseType.series
            ) {
              dotSpan.style.border = 'unset';
              dotSpan.style.borderRadius = 'unset';
              dotSpan.style.borderLeft = '5px solid transparent';
              dotSpan.style.borderRight = '5px solid transparent';
              dotSpan.style.borderBottom = '10px solid #223BF4';
            }
          }

          tippy(mountArg.el, {
            theme: 'light',
            maxWidth: 400,
            hideOnClick: true,
            delay: [300, 0],
            trigger: 'mouseenter',
            content: `
            <p style="text-align: center;">
            <p>${mountArg.event.title}</p>
            <strong>${formatToDuration(
              mountArg.event.extendedProps.startTimeString,
              mountArg.event.extendedProps.endTimeString,
            )}</strong>
            </p>
          `,
            allowHTML: true,
          });
        }}
        height="auto"
      />
    </div>
  );
};

export default ClassCalendar;
