import React from 'react';
import classNames from 'classnames';

interface IIconButton {
  text: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  active?: boolean;
  onClick?: () => void;
}

const IconButton: React.FC<IIconButton> = ({
  text,
  icon: Icon,
  active,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        'flex items-center gap-2',
        active ? 'text-black' : 'text-grey-500',
      )}
      onClick={onClick}
    >
      <Icon color="currentColor" width={24} height={24} />
      <span className="font-medium text-regular">{text}</span>
    </button>
  );
};

export default IconButton;
