import React from 'react';

import { icons } from '../../../../helpers/icons';
import { TCartItem } from '../../../../types';
import ActionMenu from '../../ActionMenu';
import Delimiter from '../../Delimiter';
import CheckoutItemPreview from '../CheckoutOverview/CheckoutSummaryTable/CheckoutItem/CheckoutItemPreview';

interface IBookingSummary {
  items: TCartItem[];
}

const BookingSummary: React.FC<IBookingSummary> = ({ items }) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex justify-between text-xs font-medium text-black">
        <div className="md:min-w-[14rem]">Course</div>
        <div className="min-w-[2rem] md:min-w-[8rem] text-center mr-7">
          Seats
        </div>
      </div>
      <Delimiter className="bg-grey-200" />
      <div className="flex flex-col gap-4">
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <div className="flex justify-between flex-grow">
              <CheckoutItemPreview item={item} />
              <div className="flex items-center gap-1 shrink-0">
                <div className="min-w-[2rem] md:min-w-[8rem] flex justify-center items-center">
                  <span>{item.studentIds?.length || 1}</span>
                </div>
                <ActionMenu
                  button={
                    <img src={icons.options} alt="" className="shrink-0" />
                  }
                  items={[
                    {
                      label: 'Add to Calendar',
                      link: `${process.env.REACT_APP_API_HOST}/ical/${item.id}`,
                    },
                  ]}
                />
              </div>
            </div>
            <Delimiter className="bg-grey-200" />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BookingSummary;
