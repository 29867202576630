import { TNavTab } from '../types';

export const LEARNIT_URL = 'https://www.learnit.com';

export const WEB_URL = process.env.REACT_APP_WEB_URL;

export const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong. Please try again later';

export const INVITE_ERROR_MESSAGE =
  'Sorry, your invite link is not valid. Please check the link and try again.';

export const NAVIGATE_PATH = {
  upcomingClasses: '/dashboard/upcoming-classes',
  pastClasses: '/dashboard/past-classes',
  publicCourses: '/library/public-courses',
  publicCourse: '/library/public-courses/:slug',
  privateCourses: '/library/private-courses',
  dashboardCalendar: '/dashboard/calendar',
  libraryCalendar: '/library/calendar',
  teamMembers: '/members/team-members',
  pendingInvites: '/members/pending-invites',
  profileSettings: '/settings/profile',
  companySettings: '/settings/company',
  companyInfo: '/info/company',
  managePrograms: '/manage/program-invites',
  checkoutLogin: '/checkout/login',
  checkoutSummary: '/checkout/summary',
  checkoutGuest: '/checkout/guest',
  firstCheckoutStep: '/checkout/confirmation',
  secondCheckoutStep: '/checkout/payment',
  checkoutThanks: '/checkout/thanks',
  login: '/login',
  signUp: '/sign-up',
  inviteParam: '/invite/:inviteToken',
  inviteQuery: '/invite',
  resetPassword: '/reset-password',
  createPassword: '/create-password',
  setPassword: '/set-password/:token',
  home: 'https://www.learnit.com/',
  any: '*',
};

export const NAVIGATION_TABS = {
  dashboard: 'dashboard',
  library: 'library',
  checkout: 'library',
  reports: 'reports',
  manage: 'manage',
  settings: 'settings',
  info: 'info',
};

export const NAVIGATION_INFO: Record<string, TNavTab> = {
  dashboard: {
    header: 'Your Scheduled Workshops',
    description: 'Workshops you are enrolled in',
    items: [
      {
        path: NAVIGATE_PATH.upcomingClasses,
        label: 'Your upcoming classes',
        shortLabel: 'Upcoming',
      },
      {
        path: NAVIGATE_PATH.pastClasses,
        label: 'Your past classes',
        shortLabel: 'Past',
      },
      {
        path: NAVIGATE_PATH.dashboardCalendar,
        label: 'Your calendar',
        shortLabel: 'Calendar',
      },
    ],
  },
  library: {
    header: 'Available Workshops',
    description: 'Public and private workshops open for enrollment',
    items: [
      {
        path: NAVIGATE_PATH.publicCourses,
        label: 'Public courses',
        shortLabel: 'Public',
      },
      {
        path: NAVIGATE_PATH.privateCourses,
        label: 'Private courses',
        shortLabel: 'Private',
      },
      {
        path: NAVIGATE_PATH.libraryCalendar,
        label: 'Calendar view',
      },
    ],
  },
  reports: {
    header: 'Reports',
    items: [],
  },
  manage: {
    header: 'Manage',
    items: [
      {
        path: NAVIGATE_PATH.managePrograms,
        label: 'Program invites',
      },
    ],
  },
  settings: {
    header: 'Settings',
    items: [
      {
        path: NAVIGATE_PATH.profileSettings,
        label: 'Account',
      },
      {
        path: NAVIGATE_PATH.companySettings,
        label: 'Company',
      },
    ],
  },
  info: {
    header: 'Info',
    items: [
      {
        path: NAVIGATE_PATH.profileSettings,
        label: 'Account',
      },
      {
        path: NAVIGATE_PATH.companyInfo,
        label: 'Company',
      },
    ],
  },
};
