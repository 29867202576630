import React from 'react';
import Lottie from 'react-lottie';

import loadingSpinnerAnimation from '../../../assets/animations/loading-spinner.json';

interface ILoadingSpinner {
  height?: number;
  width?: number;
}

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingSpinnerAnimation,
};

const LoadingSpinner: React.FC<ILoadingSpinner> = ({
  height = 200,
  width = 200,
}) => {
  return <Lottie options={animationOptions} height={height} width={width} />;
};

export default LoadingSpinner;
