import React, { useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import BackButton from '../../../components/common/buttons/BackButton';
import NavHeader from '../../../components/common/NavHeader';
import { ButtonRegular } from '../../../components/common/buttons/ButtonRegular';
import { LoginForm } from '../../../components/forms/LoginForm';
import { useAppSelector } from '../../../hooks/hooks';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { icons } from '../../../helpers/icons';

const CheckoutLogin: React.FC = () => {
  const navigate = useNavigate();
  const { user, cart } = useAppSelector((state) => state.userReducer);
  const [emailSent, setEmailSent] = useState(false);

  if (user) {
    return <Navigate to={NAVIGATE_PATH.checkoutSummary} replace />;
  }

  if (!cart.public.length) {
    return <Navigate to={NAVIGATE_PATH.publicCourses} replace />;
  }

  return (
    <div className="flex flex-col min-h-screen m-auto guest-wrapper max-lg:h-screen">
      <NavHeader
        label={
          <BackButton
            defaultRoute={NAVIGATE_PATH.publicCourses}
            usePrevious={true}
          />
        }
        className="!bg-grey-100"
        showGuestLogin={false}
      />
      <div className="flex items-center justify-between h-full">
        <div className="flex justify-between gap-8 px-4 mx-auto lg:py-32 grow max-lg:flex-col max-lg:items-center">
          <LoginForm
            className="w-full m-0 max-lg:py-10 "
            header="Log in and check out"
            setEmailSent={setEmailSent}
            isCheckoutLogin
          />
          {!emailSent && (
            <div className="grid w-full gap-2 m-0 form-container max-lg:p-4 lg:min-h-full grow">
              <div className="flex items-center justify-center grow lg:flex-col-reverse lg:justify-between">
                <img
                  src={icons.holdingBooksIllustration}
                  className="max-lg:w-16"
                  alt=""
                />
                <div className="m-0 form-title max-lg:text-xl max-lg:w-auto">
                  Check out as a guest
                </div>
              </div>
              <ButtonRegular
                text="Checkout as a guest"
                className="m-auto button-primary"
                onClick={() => navigate(NAVIGATE_PATH.checkoutGuest)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutLogin;
